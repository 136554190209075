html, body {
  color: #636b6f;
  background: #eee;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
}

#root {
  background-color: #fff;
  max-width: 720px;
  margin: 40px auto;
  padding: 20px;
  box-shadow: 0 3px 4px #00000024, 0 3px 3px -2px #0000001f, 0 1px 8px #0003;
}

@media screen and (width <= 767px) {
  #root {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

#root:before {
  content: "";
  margin-top: -20px;
  display: block;
}

#root:after {
  content: "";
  margin-bottom: -20px;
  display: block;
}

.my-20 {
  margin: 20px 0;
}

#header #logo {
  width: 180px;
  height: 180px;
  overflow: hidden;
}

@media screen and (width <= 767px) {
  #header #logo {
    border-radius: 10px;
    width: 70px;
    height: 70px;
  }
}

#header #logo img {
  width: 100%;
  height: 100%;
}

#header h1 {
  color: #212121;
  margin: 0;
  font-size: 30px;
  line-height: 35px;
}

@media screen and (width <= 767px) {
  #header h1 {
    font-size: 22px;
    line-height: 24px;
  }
}

#header #app-ad {
  margin: 0 -5px;
  display: flex;
}

#header #app-ad > span {
  margin: 0 5px;
}

#header #app-ad > span:last-child {
  color: #737373;
  font-size: 13px;
  font-weight: 700;
  line-height: 1;
}

#header #app-choice {
  color: #8d8d8d;
  font-size: 13px;
  font-weight: 700;
}

#header #app-choice img {
  margin-top: -2px;
}

#header #app-header-rate {
  color: #616161;
  align-items: center;
  margin: 3px -5px 0;
  font-size: 13px;
  display: flex;
}

#header #app-header-rate > div {
  margin: 0 5px;
}

.app-age-rate {
  color: #000000af;
  background-color: #fff;
  border: .5px solid #000000af;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 13px;
  font-family: Impact, sans-serif;
  font-size: 8px;
  font-weight: 400;
  line-height: 1;
  display: inline-flex;
}

#mobile-header #mobile-stats {
  color: #898989;
  align-items: center;
  width: 100%;
  font-size: 12px;
  display: flex;
}

#mobile-header #mobile-stats > div {
  text-align: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  flex: 1;
}

#mobile-header #mobile-stats > div:first-child, #mobile-header #mobile-stats > div:last-child {
  border: none;
}

#mobile-header #mobile-stats .app-age-rate {
  border: 1px solid #000;
  width: auto;
  height: auto;
  padding: 2px 4px;
  font-size: 13px;
}

#app-category {
  margin: 0 -7px;
  padding: 0;
  list-style: none;
  display: flex;
}

#app-category li {
  white-space: nowrap;
  color: #33691e;
  cursor: pointer;
  margin: 0 7px;
  font-size: 13px;
  font-weight: 700;
}

#app-category li:hover {
  text-decoration: underline;
}

.rating-stars {
  margin: 0 -2px;
  padding: 0;
  list-style: none;
  display: flex;
}

.rating-stars li {
  background: url("star-full.14d78e61.png") no-repeat;
  width: 10px;
  height: 10px;
  margin: 0 2px;
  display: block;
}

@media screen and (width >= 767.98px) {
  .rating-stars.big {
    margin: 0 -3px;
  }

  .rating-stars.big li {
    background: url("star-full-big.d3e02c67.png") no-repeat;
    width: 17px;
    height: 17px;
    margin: 0 3px;
  }
}

.install {
  color: #fff;
  background: #00875f;
  border: none;
  border-radius: 3px;
  width: 100%;
  padding: 7px 25px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.wishlist {
  color: #33691e;
  cursor: pointer;
  font-size: 13px;
}

.wishlist:hover {
  text-decoration: underline;
}

#gallery {
  margin: 0 -5px;
  overflow-x: auto;
}

#gallery > div {
  flex-wrap: nowrap;
  width: fit-content;
  display: flex;
}

#gallery > div > div {
  width: auto;
  height: 190px;
  margin: 0 5px;
}

#gallery > div > div img {
  border-radius: 20px;
  width: auto;
  height: 190px;
}

#description > header {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  display: none;
}

@media screen and (width <= 767px) {
  #description > header {
    display: block;
  }
}

#description > article {
  color: #333;
  max-height: 70px;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  overflow: hidden;
}

#description > article:before {
  content: "";
  z-index: 100;
  background: linear-gradient(#fff0, #fff);
  height: 50px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

#description > article.show {
  max-height: none;
}

#description > article.show:before {
  display: none;
}

#description > footer {
  text-align: center;
  padding: 10px 0;
}

#description > footer span {
  color: green;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

#description > footer span:hover {
  text-decoration: underline;
}

#changelog {
  color: #333;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  font-size: 14px;
  line-height: 24px;
}

#changelog > header {
  margin-bottom: 20px;
}

@media screen and (width <= 767px) {
  #changelog {
    display: none;
  }
}

#reviews {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

#reviews #rate-chart {
  flex-wrap: nowrap;
  margin: 20px -20px;
  padding: 0 40px;
  display: flex;
}

#reviews #rate-chart > div {
  margin: 0 20px;
}

#reviews #rate-chart .rank {
  color: #333;
  font-size: 64px;
  font-weight: 100;
  line-height: 64px;
}

@media screen and (width <= 767px) {
  #reviews #rate-chart {
    padding: 0;
  }
}

#chart {
  width: 100%;
  margin: -4px 0;
  padding: 0;
  list-style: none;
}

#chart li {
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 20px;
  margin: 4px -4px;
  display: flex;
}

#chart li > span {
  margin: 0 4px;
  display: block;
}

#chart li > span:last-child {
  width: 100%;
  height: 100%;
}

#chart li > span:last-child span {
  min-width: 2.5%;
  height: 100%;
  display: block;
}

#chart li:first-child > span > span {
  background: #57bb8a;
}

#chart li:nth-child(2) > span > span {
  background: #9ace6a;
}

#chart li:nth-child(3) > span > span {
  background: #ffcf02;
}

#chart li:nth-child(4) > span > span {
  background: #ffb235;
}

#chart li:nth-child(5) > span > span {
  background: #ff8c5a;
}

@media screen and (width <= 767px) {
  #chart {
    margin: -15px 0;
  }

  #chart li {
    height: 10px;
    margin: 15px -4px;
  }

  #chart li > span:last-child {
    background: #eee;
    border-radius: 10px;
  }

  #chart li > span:last-child span {
    border-radius: 10px;
    min-width: 5%;
    background: #00875f !important;
  }
}

#comments {
  margin: -20px 0;
  padding: 20px 0;
}

#comments .comment {
  flex-wrap: nowrap;
  margin: 20px -10px;
  display: flex;
}

#comments .comment > div {
  margin: 0 10px;
}

#comments .comment .avatar {
  background: #eee;
  border-radius: 999px;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

#comments .comment .avatar img {
  width: 100%;
  height: 100%;
}

#comments .comment .comment-title {
  align-items: center;
  margin: 0 -10px;
  display: flex;
}

#comments .comment .comment-title > div {
  margin: 0 10px;
}

#comments .comment .date {
  color: #aaa;
  font-size: 12px;
  font-weight: 400;
}

#comments .comment .comment-feedback {
  background: #e5e5e5;
  margin-top: 15px;
  padding: 10px;
  position: relative;
}

#comments .comment .comment-feedback:before {
  content: "";
  border-bottom: 10px solid #e5e5e5;
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  width: 0;
  margin-bottom: -10px;
  display: block;
  position: absolute;
  top: -10px;
  left: 20px;
}

#comments .comment .comment-feedback .feedback-header {
  margin: 0 -5px;
  display: flex;
}

#comments .comment .comment-feedback .feedback-header > div {
  margin: 0 5px;
}

#dummy-comment {
  display: none;
}

#footer {
  color: #333;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  font-size: 14px;
  line-height: 24px;
}

#footer > header {
  margin-bottom: 20px;
}

#footer .cells {
  margin: 10px -10px;
  display: flex;
}

#footer .cells .item {
  flex: 1;
  margin: 0 10px;
}

#footer .cells .item .title {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
}

#footer .cells .item .content {
  font-size: 14px;
  font-weight: 300;
}

@media screen and (width <= 767px) {
  #footer {
    display: none;
  }
}
/*# sourceMappingURL=index.353a95ca.css.map */
