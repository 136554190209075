.mobile(@content) {
  @media screen and (max-width: 767px) {
    @content();
  }
}
.tablet(@content) {
  @media screen and (min-width: 767.98px) {
    @content();
  }
}

html, body {
  width: 100%;
  padding: 0;
  margin: 0;
  background: #eee;
  color: #636b6f;
  font-family: Roboto, sans-serif;
}

#root {
  max-width: 720px;
  margin: 40px auto 40px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .12), 0 1px 8px 0 rgba(0, 0, 0, .2);
  .mobile({
    max-width: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  });
  &:before {
    content: "";
    display: block;
    margin-top: -20px;
  }
  &:after {
    content: "";
    display: block;
    margin-bottom: -20px;
  }
}

.my-20 {
  margin: 20px 0;
}

#header {
  #logo {
    width: 180px;
    height: 180px;
    overflow: hidden;
    .mobile({
      width: 70px;
      height: 70px;
      border-radius: 10px;
    });
    img {
      width: 100%;
      height: 100%;
    }
  }
  h1 {
    margin: 0;
    font-size: 30px;
    color: #212121;
    line-height: 35px;
    .mobile({
      font-size: 22px;
      line-height: 24px;
    });
  }
  #app-ad {
    display: flex;
    margin: 0 -5px;
    & > span {
      margin: 0 5px;
      &:last-child {
        font-size: 13px;
        font-weight: 700;
        line-height: 1;
        color: #737373;
      }
    }
  }
  #app-choice {
    color: #8d8d8d;
    font-weight: 700;
    font-size: 13px;
    img {
      margin-top: -2px;
    }
  }
  #app-header-rate {
    display: flex;
    margin: 3px -5px 0 -5px;
    color: #616161;
    font-size: 13px;
    align-items: center;
    & > div {
      margin: 0 5px;
    }
  }
}

.app-age-rate {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 13px;
  background-color: #fff;
  border: .5px solid rgba(0, 0, 0, .685);
  font-family: Impact, sans-serif;
  color: rgba(0, 0, 0, .685);
  font-size: 8px;
  font-weight: 400;
  line-height: 1;
}

#mobile-header {
  #mobile-stats {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: #898989;
    align-items: center;
    & > div {
      flex: 1;
      text-align: center;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      &:first-child,
      &:last-child {
        border: none;
      }
    }
    .app-age-rate {
      border: 1px solid #000;
      font-size: 13px;
      padding: 2px 4px;
      width: auto;
      height: auto;
    }
  }
}

#app-category {
  display: flex;
  margin: 0 -7px;
  padding: 0;
  list-style: none;
  li {
    margin: 0 7px;
    white-space: nowrap;
    font-size: 13px;
    color: #33691e;
    font-weight: 700;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.rating-stars {
  display: flex;
  margin: 0 -2px;
  padding: 0;
  list-style: none;
  li {
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 2px;
    background: url(./assets/star-full.png) no-repeat;
  }
  &.big {
    .tablet({
      margin: 0 -3px;
      li {
        width: 17px;
        height: 17px;
        margin: 0 3px;
        background: url(./assets/star-full-big.png) no-repeat;
      }
    });
  }
}

.install {
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  color: #fff;
  border: none;
  background: #00875f;
  padding: 7px 25px;
  border-radius: 3px;
  display: block;
  width: 100%;
}

.wishlist {
  font-size: 13px;
  color: #33691e;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

#gallery {
  overflow-x: auto;
  margin: 0 -5px;
  & > div {
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
    & > div {
      width: auto;
      height: 190px;
      margin: 0 5px;
      img {
        border-radius: 20px;
        width: auto;
        height: 190px;
      }
    }
  }
}

#description {
  & > header {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 20px;
    display: none;
    .mobile({
      display: block;
    });
  }
  & > article {
    font-size: 14px;
    line-height: 24px;
    color: #333;
    overflow: hidden;
    max-height: 70px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #fff);
      z-index: 100;
    }
    &.show {
      max-height: none;
      &:before {
        display: none;
      }
    }
  }
  & > footer {
    padding: 10px 0;
    text-align: center;
    span {
      color: green;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

#changelog {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  & > header {
    margin-bottom: 20px;
  }
  .mobile({
    display: none;
  });
}

#reviews {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  #rate-chart {
    display: flex;
    flex-wrap: nowrap;
    margin: 20px -20px;
    padding: 0 40px;
    & > div {
      margin: 0 20px;
    }
    .rank {
      color: #333;
      font-size: 64px;
      font-weight: 100;
      line-height: 64px;
    }
    .mobile({
      padding: 0;
    });
  }
}

#chart {
  list-style: none;
  margin: -4px 0;
  padding: 0;
  width: 100%;
  li {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 20px;
    margin: 4px -4px;
    align-items: center;
    & > span {
      display: block;
      margin: 0 4px;
      &:last-child {
        width: 100%;
        height: 100%;
        span {
          height: 100%;
          min-width: 2.5%;
          display: block;
        }
      }
    }
    &:nth-child(1) > span > span {
      background: #57bb8a;
    }
    &:nth-child(2) > span > span {
      background: #9ace6a;
    }
    &:nth-child(3) > span > span {
      background: #ffcf02;
    }
    &:nth-child(4) > span > span {
      background: #ffb235;
    }
    &:nth-child(5) > span > span {
      background: #ff8c5a;
    }
  }
  .mobile({
    margin: -15px 0;
    li {
      height: 10px;
      margin: 15px -4px;
      & > span:last-child {
        background: #eee;
        border-radius: 10px;
        span {
          background: #00875f !important;
          border-radius: 10px;
          min-width: 5%;
        }
      }
    }
  });
}

#comments {
  margin: -20px 0;
  padding: 20px 0;
  .comment {
    margin: 20px -10px;
    display: flex;
    flex-wrap: nowrap;
    & > div {
      margin: 0 10px;
    }
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 999px;
      overflow: hidden;
      background: #eee;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .comment-title {
      display: flex;
      align-items: center;
      margin: 0 -10px;
      & > div {
        margin: 0 10px;
      }
    }
    .date {
      color: #aaa;
      font-size: 12px;
      font-weight: 400;
    }
    .comment-feedback {
      background: #e5e5e5;
      position: relative;
      padding: 10px;
      margin-top: 15px;
      &:before {
        content: '';
        display: block;
        border-bottom: 10px solid #e5e5e5;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        margin-bottom: -10px;
        position: absolute;
        top: -10px;
        left: 20px;
        width: 0;
      }
      .feedback-header {
        display: flex;
        margin: 0 -5px;
        & > div {
          margin: 0 5px;
        }
      }
    }
  }
}

#dummy-comment {
  display: none;
}

#footer {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 20px 0;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  & > header {
    margin-bottom: 20px;
  }
  .cells {
    display: flex;
    margin: 10px -10px;
    .item {
      flex: 1;
      margin: 0 10px;
      .title {
        font-size: 14px;
        line-height: 25px;
        color: #333;
        font-weight: 500;
      }
      .content {
        font-weight: 300;
        font-size: 14px;
      }
    }
  }
  .mobile({
    display: none;
  })
}